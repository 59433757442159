<template>
  <svg viewBox="0 0 51 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_22618_209765)">
      <path
        d="M4.90527 22.5569L5.26629 22.9198V29.749H8.34622L8.70723 30.11V26.9004L8.34622 26.0298L11.2005 28.8841L14.0548 26.0298V29.749H17.1366L17.4976 30.11V22.5569H13.8837L14.0341 22.9198L11.2005 25.7515V25.2401L8.51732 22.5569H4.90527ZM23.4337 22.5569L23.6293 22.9198L19.1842 29.749H22.2134L22.7737 28.8822H28.2416L28.802 29.749H31.8311L32.4967 30.11L27.5816 22.5569H23.4337ZM33.4914 22.5569L33.8524 22.9198V29.749H36.9324L37.2934 30.11V26.5111L36.9324 25.7948L42.2743 29.749H45.6607L46.0217 30.11V22.5569H42.2197L42.5808 22.9198V26.9041L42.2197 26.1877L37.3272 22.5569H33.4914ZM25.5077 24.6384L27.5177 27.7446L26.854 27.3836H24.1614L25.5077 25.3021V24.6384Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.5077 24.6377V25.3015L24.1608 27.3828L23.4971 27.7443L25.5077 24.6377Z"
        fill="currentColor"
      />
      <path
        d="M48.6674 29.7504C48.7389 29.0095 48.7749 28.2592 48.7749 27.5002C48.7749 14.6454 38.355 4.22559 25.5002 4.22559C12.6462 4.22559 2.22559 14.6454 2.22559 27.5002C2.22559 28.2592 2.26236 29.0095 2.33308 29.7504L2.73421 30.1112C2.6368 29.2541 2.58701 28.3833 2.58701 27.5002C2.58701 14.8453 12.8453 4.58635 25.5002 4.58635C38.1559 4.58635 48.4142 14.8453 48.4142 27.5002C48.4142 28.3833 48.3644 29.2541 48.2669 30.1112L48.6674 29.7504Z"
        fill="currentColor"
      />
      <path
        d="M25.4996 2.5C11.6926 2.5 0.5 13.6925 0.5 27.4996C0.5 28.2579 0.5339 29.0082 0.599567 29.7498H2.33249C2.26178 29.0089 2.225 28.2586 2.225 27.4996C2.225 14.6448 12.6456 4.22497 25.4996 4.22497C38.3544 4.22497 48.7743 14.6448 48.7743 27.4996C48.7743 28.2586 48.7383 29.0089 48.6668 29.7498H50.3997C50.4661 29.0082 50.5 28.2579 50.5 27.4996C50.5 13.6925 39.3067 2.5 25.4996 2.5Z"
        fill="currentColor"
      />
      <path
        d="M8.36855 22.9189H5.26562V29.7496H8.34696V26.0291L11.201 28.8839L14.0557 26.0291V29.7496H17.1371V22.9189H14.0334L11.201 25.7514L8.36855 22.9189Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.5187 27.7447H23.4974L25.5081 24.6381L27.5187 27.7447ZM27.386 22.9189H23.6294L19.1846 29.7496H22.214L22.7745 28.8818H28.2409L28.8015 29.7496H31.8316L27.386 22.9189Z"
        fill="currentColor"
      />
      <path
        d="M37.2077 22.9189H33.8516V29.7496H36.9321V25.7954L42.2737 29.7496H45.6616V22.9189H42.5804V26.9049L37.2077 22.9189Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_22618_209765">
        <rect
          width="50"
          height="33.3333"
          fill="white"
          transform="translate(0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
