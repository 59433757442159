<template>
  <svg
    width="60"
    height="19"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 680 10"
  >
    <path id="XMLID_141_" fill="#000000" d="M0 141.9h678v43.8H0z" />
    <path
      id="XMLID_136_"
      class="st1"
      d="M.1 115.1V.5h141.4c45.9 0 78.6 20.9 78.6 56.5 0 33.9-25.9 58.1-79.9 58.1H.1zm113.6-29.3c17.6 0 27.9-15 27.9-26.5 0-11.7-9.2-25.4-27.4-25.4H75.7v51.9h38z"
    />
    <path
      id="XMLID_135_"
      class="st1"
      d="M344 28.9l-51.9 86.4h-72.2L288.3 0h111.2l68.7 115.3h-152l18.1-30h43.5z"
    />
    <path
      id="XMLID_134_"
      class="st1"
      d="M556.8 115h-74.9V0h198.2v33.5H556.8V54H678v32.5H556.8z"
    />
  </svg>
</template>
