<template>
  <svg viewBox="0 0 60 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_8248_74924)">
      <path d="M27.2181 15H23.1494V15.9253H27.2181V15Z" fill="currentColor" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39.3301 17.2676V24.5339C39.3301 24.9564 39.8873 25.4592 40.3123 25.4592H48.2147C48.6402 25.4592 49.1498 24.9253 49.1498 24.4411V21.6175H44.9889V23.7004H43.4925V18.1005H44.9889V19.8131H49.1508V17.4547C49.1469 17.173 49.0321 16.9039 48.8308 16.7047C48.6296 16.5055 48.3578 16.3921 48.0734 16.3884H40.4526C39.9582 16.3884 39.3301 16.8099 39.3301 17.2676Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M54.2029 18.1005H55.8862V25.4587H60.0015V17.4989C60.0015 16.8967 59.4544 16.3884 58.8324 16.3884H54.2029V15H50.2734V25.4587H54.2029V18.1005Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.30225 16.4346H6.68665V19.3951L6.74186 20.2743H4.30225V16.4346ZM10.1951 20.8312C10.5431 20.5919 11.0365 20.4579 11.0365 19.8131V15.972C11.0365 15.4832 10.5472 15 10.0548 15H0V25.4587H4.30225V21.7104H6.73376V25.4587H11.036V21.8027C11.0365 21.329 10.6378 20.8674 10.1951 20.8312Z"
        fill="currentColor"
      />
      <path
        d="M27.2181 16.3884H23.1494V25.4587H27.2181V16.3884Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.5955 18.1006H34.139V19.0725L35.8689 19.1302L38.114 19.1187V17.3123C38.114 16.824 37.5745 16.387 37.0852 16.387H29.4158C28.9761 16.387 28.3398 16.8973 28.3398 17.3123V20.6446C28.3398 21.2021 28.7193 21.4776 29.2755 21.4776H34.1385V23.699H32.5955V22.4505H28.3404V24.4868C28.3404 24.8962 28.8869 25.4587 29.276 25.4587H37.2721C37.5917 25.4587 38.114 24.9148 38.114 24.4868V20.897C38.114 20.3144 37.8004 19.9978 37.2756 19.9978H32.5955V18.1006Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.368 18.1005H17.9581V19.9978H16.368V18.1005ZM16.368 21.6632H22.0734V17.4984C22.0734 16.9233 21.5754 16.3879 20.9974 16.3879H13.1877C12.7019 16.3879 12.1123 16.8968 12.1123 17.4061V24.5339C12.1123 24.9032 12.7202 25.4592 13.1411 25.4592H21.0435C21.5937 25.4592 22.0724 24.8731 22.0724 24.3026V22.451H17.9581V23.7933H16.368V21.6632Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_8248_74924">
        <rect width="60" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
