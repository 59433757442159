<template>
  <svg viewBox="0 0 60 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5699 15.8164C10.6197 15.8194 10.6676 15.8364 10.7082 15.8654C10.7488 15.8944 10.7804 15.9343 10.7994 15.9804C10.8184 16.0266 10.824 16.0771 10.8156 16.1263C10.8071 16.1755 10.785 16.2213 10.7517 16.2585L7.34437 19.6685L11.438 24.9554C11.483 25.013 11.5109 25.082 11.5186 25.1547C11.5262 25.2273 11.5134 25.3006 11.4814 25.3663C11.4495 25.432 11.3997 25.4874 11.3378 25.5263C11.276 25.5651 11.2044 25.5857 11.1314 25.5859H8.08902C8.01042 25.5859 7.93286 25.568 7.86224 25.5335C7.79162 25.499 7.72981 25.4488 7.68152 25.3868L5.04402 21.9715L3.81089 23.1807V25.0669C3.81054 25.2036 3.75599 25.3346 3.65919 25.4312C3.56239 25.5277 3.43126 25.5819 3.29454 25.5819H0.507057C0.37185 25.5798 0.242889 25.5247 0.14802 25.4283C0.0531503 25.3319 -1.61654e-05 25.2021 3.68697e-09 25.0669V16.3275C0.00139597 16.1917 0.0562307 16.0619 0.152628 15.9663C0.249024 15.8706 0.379222 15.8168 0.515022 15.8164H3.30251C3.43785 15.8175 3.56737 15.8716 3.66319 15.9672C3.75902 16.0628 3.81349 16.1921 3.81488 16.3275V19.3499L7.19835 15.9664C7.24658 15.9182 7.30394 15.8801 7.36707 15.8544C7.43021 15.8286 7.49785 15.8157 7.56604 15.8164H10.5699Z"
      fill="currentColor"
    />
    <path
      d="M55.3989 23.1567H59.4833C59.6198 23.1574 59.7504 23.2121 59.8467 23.3089C59.9429 23.4057 59.997 23.5366 59.997 23.6731V25.0655C59.997 25.2024 59.9426 25.3338 59.8457 25.4306C59.7489 25.5274 59.6176 25.5818 59.4806 25.5818H52.1801C52.1123 25.5818 52.0452 25.5685 51.9827 25.5425C51.9201 25.5165 51.8633 25.4785 51.8154 25.4305C51.7676 25.3826 51.7297 25.3256 51.7039 25.263C51.6781 25.2004 51.6649 25.1332 51.665 25.0655V16.3274C51.6649 16.2596 51.6781 16.1925 51.7039 16.1299C51.7297 16.0672 51.7676 16.0103 51.8154 15.9624C51.8633 15.9144 51.9201 15.8763 51.9827 15.8504C52.0452 15.8244 52.1123 15.811 52.1801 15.811H54.8866C55.0229 15.8117 55.1535 15.8665 55.2495 15.9633C55.3456 16.0601 55.3993 16.191 55.3989 16.3274V23.1567Z"
      fill="currentColor"
    />
    <path
      d="M49.3357 15.8163C49.4717 15.8163 49.6023 15.87 49.699 15.9657C49.7957 16.0614 49.8507 16.1914 49.8521 16.3274V17.6906C49.8522 17.7583 49.8391 17.8255 49.8132 17.8881C49.7874 17.9507 49.7495 18.0077 49.7017 18.0556C49.6538 18.1036 49.597 18.1417 49.5344 18.1676C49.4719 18.1936 49.4048 18.2069 49.337 18.2069H43.9691V19.5556H48.2911C48.4278 19.5559 48.5588 19.6105 48.6553 19.7073C48.7519 19.8041 48.8061 19.9352 48.8061 20.0719V21.2599C48.8047 21.3956 48.7498 21.5252 48.6534 21.6207C48.557 21.7161 48.4268 21.7696 48.2911 21.7696H43.9691V23.1567H49.5109C49.6476 23.1571 49.7786 23.2116 49.8752 23.3084C49.9717 23.4052 50.026 23.5364 50.026 23.6731V25.0655C50.026 25.2022 49.9717 25.3333 49.8752 25.4301C49.7786 25.5269 49.6476 25.5815 49.5109 25.5818H40.8259C40.6892 25.5818 40.5581 25.5276 40.4613 25.4311C40.3645 25.3345 40.3099 25.2035 40.3096 25.0668V16.3274C40.3096 16.1904 40.364 16.0591 40.4608 15.9623C40.5576 15.8654 40.689 15.811 40.8259 15.811L49.3357 15.8163Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.8359 20.7076C15.8356 20.2119 15.9824 19.7273 16.2576 19.315C16.5328 18.9027 16.9241 18.5813 17.382 18.3914C17.8399 18.2015 18.3438 18.1517 18.8301 18.2482C19.3163 18.3448 19.7629 18.5834 20.1136 18.9338C20.4642 19.2842 20.703 19.7308 20.7998 20.2169C20.8966 20.7031 20.847 21.207 20.6574 21.6651C20.4678 22.1231 20.1466 22.5145 19.7344 22.79C19.3223 23.0654 18.8377 23.2124 18.342 23.2124C17.6775 23.2124 17.0403 22.9486 16.5704 22.4789C16.1004 22.0092 15.8362 21.3721 15.8359 20.7076ZM12.2002 20.7076C12.2002 17.8657 14.9492 15.5627 18.342 15.5627C21.7347 15.5627 24.4837 17.8684 24.4837 20.7076C24.4837 23.5469 21.7334 25.8526 18.342 25.8526C14.9505 25.8526 12.2002 23.5509 12.2002 20.7076Z"
      fill="currentColor"
    />
    <path
      d="M34.3309 22.2727C34.0428 22.9231 33.3526 23.4553 32.008 23.3452C30.4032 23.2124 29.4568 22.1651 29.4568 20.7103C29.4568 19.2555 30.5996 18.0755 32.008 18.0755C32.4436 18.0778 32.8713 18.1925 33.2497 18.4086C33.628 18.6246 33.9442 18.9346 34.1676 19.3086C34.2139 19.3843 34.2789 19.4469 34.3563 19.4904C34.4336 19.5338 34.5209 19.5567 34.6096 19.5568H37.2564C37.3934 19.5568 37.5247 19.5024 37.6215 19.4056C37.7184 19.3088 37.7728 19.1774 37.7728 19.0405C37.7726 18.9592 37.753 18.8791 37.7157 18.8069C36.8117 16.9074 34.5964 15.5654 32.008 15.5654C28.6152 15.5654 25.8662 17.8684 25.8662 20.7103C25.8662 23.5522 28.6152 25.8552 32.008 25.8552C33.5332 25.8731 35.0201 25.377 36.229 24.4469L36.5224 25.2433C36.559 25.3419 36.6249 25.427 36.7113 25.487C36.7977 25.5471 36.9004 25.5792 37.0055 25.5791H37.7542C37.8908 25.5791 38.0218 25.5249 38.1184 25.4283C38.2149 25.3317 38.2692 25.2007 38.2692 25.0641V20.8165C38.2692 20.6799 38.2149 20.5489 38.1184 20.4523C38.0218 20.3558 37.8908 20.3015 37.7542 20.3015H33.419C33.2823 20.3015 33.1511 20.3557 33.0543 20.4523C32.9575 20.5488 32.903 20.6798 32.9026 20.8165V21.7603C32.9028 21.8279 32.9163 21.8949 32.9423 21.9573C32.9684 22.0197 33.0065 22.0764 33.0544 22.1241C33.1024 22.1718 33.1592 22.2096 33.2218 22.2353C33.2843 22.261 33.3513 22.2742 33.419 22.274L34.3309 22.2727Z"
      fill="currentColor"
    />
    <path
      d="M14.1391 15C14.2572 14.9999 14.3721 15.038 14.4667 15.1087C14.5613 15.1793 14.6305 15.2787 14.664 15.3919C14.6974 15.5051 14.6934 15.6261 14.6523 15.7369C14.6113 15.8476 14.5356 15.9421 14.4364 16.0062L12.7971 17.0574C12.7403 17.0921 12.6751 17.1105 12.6086 17.1105C12.56 17.1105 12.5118 17.1009 12.4669 17.0823C12.422 17.0637 12.3811 17.0364 12.3468 17.0021C12.3124 16.9677 12.2851 16.9268 12.2665 16.8819C12.2479 16.837 12.2383 16.7888 12.2383 16.7402V15.515C12.2386 15.3783 12.2932 15.2473 12.39 15.1508C12.4868 15.0542 12.6179 15 12.7546 15H14.1391Z"
      fill="currentColor"
    />
    <path
      d="M22.5424 15C22.4244 14.9999 22.3094 15.038 22.2148 15.1087C22.1202 15.1793 22.051 15.2787 22.0175 15.3919C21.9841 15.5051 21.9882 15.6261 22.0292 15.7369C22.0702 15.8476 22.1459 15.9421 22.2451 16.0062L23.8844 17.0574C23.9413 17.0919 24.0064 17.1103 24.0729 17.1105C24.1215 17.1105 24.1697 17.1009 24.2146 17.0823C24.2596 17.0637 24.3004 17.0364 24.3348 17.0021C24.3692 16.9677 24.3964 16.9268 24.415 16.8819C24.4337 16.837 24.4432 16.7888 24.4432 16.7402V15.515C24.4429 15.3783 24.3883 15.2473 24.2915 15.1508C24.1947 15.0542 24.0636 15 23.9269 15H22.5424Z"
      fill="currentColor"
    />
  </svg>
</template>
