<template>
  <svg
    width="61"
    height="60"
    viewBox="0 2 60 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.18956 45.2659V45.3315C6.18956 45.666 6.30399 45.7466 6.86713 45.7974V45.9527C6.45456 45.9198 6.26485 45.9109 6.05706 45.9109C5.84927 45.9109 5.66256 45.9109 5.25 45.9527V45.7974C5.81012 45.7466 5.89444 45.6809 5.92757 45.2659L6.13235 41.1478V41.0821C6.13235 40.7387 6.00888 40.6581 5.45779 40.6163V40.452C5.65176 40.4737 5.8468 40.4847 6.042 40.4849C6.27852 40.4882 6.51497 40.4752 6.74968 40.446L8.29754 44.791L9.87251 40.446C10.1136 40.4711 10.3558 40.4841 10.5983 40.4849C10.7884 40.4842 10.9784 40.4732 11.1674 40.452V40.6163C10.6224 40.6581 10.4899 40.7387 10.4899 41.0732V41.1478L10.5802 45.2659C10.5802 45.666 10.6886 45.7466 11.2578 45.7974V45.9527C10.6295 45.8651 9.99189 45.8651 9.36359 45.9527V45.7884C9.88155 45.7646 10.0381 45.657 10.0381 45.3315V45.2569L9.96286 40.9089L8.21022 45.7705H8.10481L6.40337 40.9089L6.20462 45.2659H6.18956Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.8922 44.6606C14.8079 45.5117 14.2899 46.0104 13.5069 46.0104C12.462 46.0104 11.8838 45.3056 11.8838 44.0335C11.8838 42.7613 12.4861 41.9819 13.4407 41.9819C14.3953 41.9819 14.8922 42.6419 14.8832 43.8692H12.3957C12.3957 45.1504 12.7571 45.7566 13.516 45.7566C14.1183 45.7566 14.4706 45.3803 14.5609 44.6606H14.8922ZM14.3712 43.5736C14.323 42.6509 14.0098 42.185 13.4317 42.185C12.8535 42.185 12.5282 42.6777 12.4198 43.5736H14.3712Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.519 42.1192C15.8537 42.0913 16.1847 42.0293 16.5067 41.9341L16.5549 42.397L16.573 42.7344C16.6226 42.5231 16.742 42.3342 16.9122 42.1979C17.0825 42.0616 17.2937 41.9855 17.5125 41.9819C17.5986 41.9745 17.6853 41.9847 17.7672 42.012C17.8491 42.0393 17.9245 42.083 17.9886 42.1405C18.0528 42.1979 18.1043 42.2678 18.14 42.3458C18.1757 42.4239 18.1948 42.5084 18.1961 42.5941C18.1961 42.83 18.0726 42.9793 17.8739 42.9793C17.8362 42.9817 17.7984 42.9766 17.7628 42.9643C17.7271 42.9521 17.6943 42.9328 17.6662 42.9078C17.6382 42.8828 17.6154 42.8524 17.5994 42.8185C17.5833 42.7846 17.5743 42.7479 17.5727 42.7105C17.5751 42.6592 17.5863 42.6087 17.6059 42.5612C17.6105 42.5426 17.6105 42.5231 17.6059 42.5045C17.6059 42.391 17.4975 42.3074 17.3409 42.3074C16.8952 42.3074 16.6001 42.8807 16.6001 43.7557V45.3981C16.6001 45.7236 16.6994 45.7893 17.2023 45.8132V45.9536C16.6382 45.8859 16.068 45.8859 15.5039 45.9536V45.8132C15.9978 45.7893 16.1062 45.7236 16.1062 45.3981V42.7493C16.1062 42.2596 16.1062 42.2506 15.531 42.2357V42.1192H15.519Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.2627 43.1819H21.1061C21.0679 42.988 21.0009 42.8008 20.9073 42.6264C20.8301 42.496 20.7209 42.387 20.5898 42.3098C20.4587 42.2325 20.3099 42.1894 20.1575 42.1845C19.5221 42.1845 19.1457 42.8713 19.1457 44.0329C19.1457 45.1946 19.4739 45.756 20.1334 45.756C20.6453 45.756 20.9314 45.4126 21.0368 44.669H21.3681C21.2687 45.5261 20.823 46.0099 20.1063 46.0099C19.1848 46.0099 18.5645 45.2155 18.5645 44.0329C18.5645 42.8504 19.1908 41.9814 20.1304 41.9814C20.2792 41.9749 20.4276 42.0016 20.5647 42.0595C20.7017 42.1174 20.824 42.205 20.9224 42.3159L21.1272 41.9067H21.2687V43.1819H21.2627Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.0747 44.6606C24.9934 45.5117 24.4724 46.0104 23.6925 46.0104C22.6445 46.0104 22.0693 45.3056 22.0693 44.0335C22.0693 42.7613 22.6716 41.9819 23.6262 41.9819C24.5809 41.9819 25.0747 42.6419 25.0687 43.8692H22.5783C22.5783 45.1504 22.9426 45.7566 23.6985 45.7566C24.3008 45.7566 24.6561 45.3803 24.7465 44.6606H25.0747ZM24.5568 43.5736C24.5056 42.6509 24.1924 42.185 23.6172 42.185C23.042 42.185 22.7138 42.6777 22.6054 43.5736H24.5568Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29.3253 45.8043C28.9953 45.8261 28.6695 45.8893 28.3556 45.9924C28.3011 45.7591 28.2698 45.521 28.2622 45.2817C28.1852 45.4938 28.0429 45.6766 27.8554 45.8042C27.6679 45.9317 27.4446 45.9976 27.2173 45.9924C26.362 45.9924 25.7598 45.1771 25.7598 44.0065C25.7598 42.8359 26.362 42.0057 27.2083 42.0057C27.672 42.0057 27.9671 42.2118 28.2291 42.7344V41.1547C28.2291 40.7635 28.1719 40.7157 27.6449 40.7157V40.6022C28.0144 40.566 28.3808 40.5031 28.7411 40.4141V45.1264C28.7411 45.4638 28.7652 45.5594 28.8736 45.6251C28.982 45.6908 28.988 45.6758 29.3253 45.6908V45.8043ZM26.3139 44.0125C26.3139 45.1324 26.6692 45.7714 27.2866 45.7714C27.9039 45.7714 28.2412 45.1742 28.2412 44.0961C28.2412 42.9016 27.8949 42.2416 27.2625 42.2416C26.6301 42.2416 26.3139 42.8389 26.3139 44.0125Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.8795 44.6606C32.7952 45.5117 32.2772 46.0104 31.4942 46.0104C30.4493 46.0104 29.8711 45.3056 29.8711 44.0335C29.8711 42.7613 30.4734 41.9819 31.428 41.9819C32.3826 41.9819 32.8795 42.6419 32.8705 43.8692H30.383C30.383 45.1504 30.7444 45.7566 31.5033 45.7566C32.1056 45.7566 32.4579 45.3803 32.5482 44.6606H32.8795ZM32.3585 43.5736C32.3103 42.6509 31.9971 42.185 31.422 42.185C30.8468 42.185 30.5185 42.6777 30.4071 43.5736H32.3585Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M33.6543 44.699H33.8079C33.8421 44.8992 33.9082 45.0927 34.0036 45.2724C34.0926 45.4316 34.2238 45.5636 34.3831 45.6542C34.5423 45.7447 34.7235 45.7903 34.907 45.786C35.0182 45.7958 35.1301 45.7833 35.2363 45.7493C35.3424 45.7152 35.4406 45.6603 35.5249 45.5879C35.6092 45.5154 35.6779 45.4269 35.727 45.3275C35.776 45.2282 35.8044 45.1201 35.8105 45.0096C35.8105 44.7319 35.687 44.5766 35.3497 44.4123C35.2684 44.3705 35.2684 44.3705 34.5246 44.0689C33.9073 43.8151 33.7266 43.5941 33.7266 43.0954C33.7254 42.9439 33.7564 42.794 33.8175 42.6551C33.8785 42.5163 33.9683 42.3917 34.0811 42.2895C34.1938 42.1873 34.3269 42.1098 34.4718 42.0618C34.6167 42.0139 34.7701 41.9967 34.9221 42.0114C35.2093 42.0083 35.4907 42.0916 35.7292 42.2503L35.8436 41.9517H35.9851V43.185H35.8285C35.7532 42.534 35.4732 42.2234 34.9552 42.2234C34.8545 42.2165 34.7534 42.2298 34.6578 42.2623C34.5623 42.2948 34.4744 42.346 34.3991 42.4128C34.3239 42.4796 34.2629 42.5607 34.2198 42.6513C34.1767 42.7418 34.1524 42.8401 34.1482 42.9401C34.1482 43.2119 34.2716 43.3731 34.6089 43.5374C34.7324 43.5941 34.7896 43.627 35.41 43.8718C36.0303 44.1167 36.2471 44.3705 36.2471 44.8513C36.2431 45.013 36.206 45.1721 36.1381 45.3191C36.0702 45.4661 35.9729 45.5979 35.8521 45.7065C35.7313 45.8151 35.5895 45.8983 35.4353 45.951C35.2812 46.0037 35.1178 46.0247 34.9552 46.013C34.6019 46.0134 34.2578 45.9014 33.9735 45.6934L33.7928 46.0219H33.6543V44.699Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M36.668 43.5259V43.8693H38.7519V43.5259H36.668Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M41.1089 40.4801C41.5038 40.4396 41.8998 40.5461 42.2201 40.7787C42.3958 40.9292 42.5286 41.1228 42.6053 41.3401C42.682 41.5574 42.6999 41.7909 42.6572 42.0172C42.6146 42.2435 42.5128 42.4547 42.3622 42.6299C42.2115 42.8051 42.0171 42.938 41.7985 43.0154C42.5423 43.1886 42.9368 43.6545 42.9368 44.3562C42.9369 44.5949 42.8829 44.8305 42.7786 45.0455C42.6743 45.2606 42.5225 45.4496 42.3345 45.5985C42.0153 45.8344 41.684 45.9151 41.0577 45.9151H39.04V45.7598C39.6423 45.712 39.7146 45.6463 39.7146 45.2223V41.1639C39.7146 40.7488 39.6333 40.6832 39.04 40.6354V40.4801H41.1089ZM40.868 42.9617C41.3618 42.9617 41.5847 42.896 41.7925 42.6839C41.8976 42.5663 41.9784 42.4293 42.0301 42.2807C42.0818 42.1321 42.1033 41.9748 42.0936 41.8179C42.1129 41.5242 42.0288 41.2329 41.8557 40.9937C41.752 40.8825 41.6231 40.7973 41.4796 40.7454C41.3361 40.6935 41.1821 40.6762 41.0306 40.6951H40.2657V42.9497L40.868 42.9617ZM40.2657 45.2491C40.2657 45.6344 40.347 45.7001 40.8258 45.7001H41.1269C41.8858 45.7001 42.3466 45.2073 42.3466 44.401C42.365 44.0608 42.2495 43.7267 42.0243 43.4693C41.9024 43.3619 41.7587 43.2815 41.6029 43.2336C41.4471 43.1857 41.2828 43.1714 41.1209 43.1916H40.2566V45.2491H40.2657Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M46.7114 44.6606C46.6301 45.5117 46.1092 46.0104 45.3292 46.0104C44.2812 46.0104 43.7061 45.3056 43.7061 44.0335C43.7061 42.7613 44.3083 41.9819 45.263 41.9819C46.2176 41.9819 46.7114 42.6419 46.7024 43.8692H44.215C44.215 45.1504 44.5794 45.7566 45.3382 45.7566C45.9405 45.7566 46.2929 45.3803 46.3832 44.6606H46.7114ZM46.1935 43.5736C46.1423 42.6509 45.8321 42.185 45.2539 42.185C44.6757 42.185 44.3505 42.6777 44.2391 43.5736H46.1935Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M47.3369 42.1516C47.6761 42.1108 48.0089 42.0286 48.3277 41.9067C48.361 42.1483 48.3801 42.3916 48.3849 42.6354C48.4902 42.4432 48.6467 42.2833 48.8373 42.1732C49.028 42.0632 49.2454 42.0072 49.466 42.0113C49.6656 42.0068 49.8629 42.0533 50.0391 42.1464C50.2153 42.2395 50.3643 42.376 50.4718 42.5428C50.6103 42.8098 50.6728 43.1092 50.6525 43.4088V45.4036C50.6525 45.7291 50.7519 45.7948 51.2548 45.8187V45.9591C50.9699 45.9259 50.6833 45.9089 50.3965 45.9083C50.1137 45.9089 49.8311 45.9259 49.5503 45.9591V45.8187C50.0502 45.8038 50.1526 45.7291 50.1526 45.4036V43.4267C50.1821 43.1611 50.1307 42.8927 50.005 42.6563C49.9329 42.5576 49.8369 42.4785 49.7259 42.4263C49.6148 42.3741 49.4923 42.3505 49.3696 42.3577C49.2235 42.3562 49.079 42.3886 48.9477 42.4524C48.8165 42.5161 48.7021 42.6095 48.6138 42.725C48.4747 42.9437 48.4132 43.2023 48.4391 43.4596V45.4066C48.4391 45.7321 48.5385 45.7978 49.0414 45.8217V45.9621C48.4783 45.8944 47.909 45.8944 47.3459 45.9621V45.8217C47.8398 45.7978 47.9482 45.7321 47.9482 45.4066V42.7578C47.9482 42.4592 47.9482 42.4054 47.882 42.3487C47.8157 42.292 47.7585 42.292 47.37 42.277V42.1606L47.3369 42.1516Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M54.0537 42.2597H52.9907C52.4727 42.2597 52.3734 42.3582 52.0873 43.0749H51.9488L52.1294 42.0715H54.7734L52.4517 45.7387H53.5629C53.7117 45.7644 53.8649 45.7419 53.9999 45.6747C54.1349 45.6075 54.2446 45.4991 54.3127 45.3654C54.4247 45.1968 54.5081 45.0112 54.5596 44.8159H54.7012L54.5024 45.9268H51.75L54.0658 42.2597H54.0537Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.1027 13C27.534 12.9999 25.0446 13.883 23.0589 15.4989C21.0731 17.1148 19.7138 19.3635 19.2125 21.8618C18.7112 24.3601 19.099 26.9535 20.3098 29.2001C21.5206 31.4467 23.4795 33.2074 25.8527 34.1824C28.2258 35.1573 30.8665 35.286 33.3247 34.5467C35.7829 33.8074 37.9065 32.2457 39.3337 30.1277C40.7609 28.0098 41.4034 25.4667 41.1518 22.9316C40.9001 20.3966 39.7698 18.0265 37.9535 16.2252C36.9249 15.1996 35.7014 14.3864 34.3539 13.8329C33.0063 13.2793 31.5614 12.9962 30.1027 13ZM22.9657 31.1027C21.3111 29.4617 20.2814 27.3026 20.0522 24.9932C19.8229 22.6838 20.4081 20.367 21.7083 18.4375C23.0084 16.508 24.9429 15.0852 27.1822 14.4115C29.4216 13.7378 31.8272 13.8549 33.9892 14.7428C36.1512 15.6308 37.936 17.2346 39.0393 19.281C40.1426 21.3275 40.4963 23.69 40.04 25.966C39.5838 28.242 38.3459 30.2908 36.5372 31.7633C34.7285 33.2357 32.4609 34.0407 30.1208 34.0412C28.7916 34.0442 27.4749 33.7861 26.2468 33.2817C25.0187 32.7774 23.9035 32.0368 22.9657 31.1027Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40.0413 19.6145C39.1303 17.5444 37.5885 15.8091 35.6323 14.6523C33.6761 13.4954 31.4037 12.9751 29.134 13.1642C25.6769 13.3703 22.2439 15.6368 20.5123 18.6679C20.1695 19.2656 19.8958 19.8997 19.6962 20.5582C18.3712 24.4403 19.633 28.7375 22.283 31.5297L22.807 31.9298C20.9466 30.0606 19.779 27.6218 19.4945 25.0107C19.2991 22.8708 19.7722 20.7232 20.8496 18.859C21.8719 17.0986 23.3751 15.6607 25.186 14.7111C28.1523 13.0388 32.6212 13.1851 35.476 15.0097C37.1329 15.9763 38.5039 17.3585 39.4511 19.0173C40.1327 20.174 40.597 21.4438 40.8213 22.765C40.7422 21.6788 40.4785 20.6136 40.0413 19.6145Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40.3476 24.7807C40.4861 22.6157 39.9892 20.364 38.9172 18.6917C38.5016 18.0676 37.7969 17.1777 37.297 16.5864C39.7724 18.8351 40.8445 21.7646 40.7903 24.9061C40.5343 28.9734 37.3663 32.8645 33.4424 34.0381C31.5045 34.6676 29.4233 34.722 27.4545 34.1947C25.4858 33.6673 23.7153 32.5812 22.3604 31.0697C21.8716 30.5442 21.4461 29.9639 21.0926 29.3407C20.9481 29.1377 21.1077 28.9943 21.2252 29.1466C22.1255 30.6597 23.4037 31.9174 24.9365 32.7985C26.4693 33.6796 28.2051 34.1544 29.9767 34.1772C31.7483 34.2 33.4959 33.7701 35.0512 32.9287C36.6066 32.0874 37.9172 30.863 38.8569 29.3736C39.125 29.4064 39.2033 29.2213 39.0497 29.0481C39.8338 27.7549 40.2799 26.2881 40.3476 24.7807Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.7413 28.6985C20.3649 27.7369 19.7777 26.6081 19.7295 24.4192C19.8769 25.9424 20.3175 27.4235 21.0274 28.7821C21.1328 28.9762 20.9461 29.1405 20.7413 28.6985Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.1934 25.924L38.6254 29.2955C38.7609 29.3582 39.0379 29.5134 39.1373 29.3671C39.2367 29.2208 39.2156 29.2178 38.9898 29.0147L38.9536 29.0595L31.8708 23.1049L30.4193 13.8476C30.3711 13.6564 30.347 13.5818 30.1874 13.5818C30.0278 13.5818 30.0338 13.7669 30.0097 13.8386L28.504 22.9825L21.382 28.5937C21.2736 28.6803 21.0809 28.7191 20.9062 28.4175L21.117 28.9341L21.4874 29.4059C21.442 29.3243 21.4114 29.2353 21.3971 29.1432L21.4453 29.0147L30.1784 25.918L30.1934 25.924Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38.7705 29.1711L30.3867 24.208V25.9997L30.218 25.937L30.0374 25.9878V24.1363L21.2832 28.8725L28.9322 23.3957L28.4413 23.0702L28.5317 22.9866L28.5558 22.8522V22.8134L29.0225 23.112L30.0253 13.8755C30.0253 13.7053 30.1006 13.5769 30.212 13.5769C30.3235 13.5769 30.3927 13.7859 30.3927 13.9024L30.471 23.7033L31.8502 22.897L31.8834 23.0881L32.0159 23.1986L31.5491 23.4823C31.5491 23.4823 38.6349 28.7948 38.8578 28.9681C39.0806 29.1413 38.8578 29.2368 38.7614 29.1562L38.7705 29.1711ZM29.8928 24.0288L29.4532 23.7302L27.4084 25.289L29.8808 24.0377L29.8928 24.0288ZM31.0281 23.8138L30.6637 24.0288L38.7012 29.0099L31.0281 23.8138ZM29.4893 23.4226L30.0313 23.769L30.2542 13.777L29.4893 23.4226Z"
      fill="currentColor"
    />
  </svg>
</template>
