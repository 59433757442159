import TruckIcon from '@/components/icons/TruckIcon.vue'
import TrailerIcon from '@/components/icons/TrailerIcon.vue'
import TransporterIcon from '@/components/icons/TransporterIcon.vue'
import MercedesIcon from '@/components/icons/manufacturers/MercedesIcon.vue'
import DafIcon from '@/components/icons/manufacturers/DafIcon.vue'
import ManIcon from '@/components/icons/manufacturers/ManIcon.vue'
import VolvoIcon from '@/components/icons/manufacturers/VolvoIcon.vue'
import KroneIcon from '@/components/icons/manufacturers/KroneIcon.vue'
import KogelIcon from '@/components/icons/manufacturers/KogelIcon.vue'
import SchmitzCargobullIcon from '@/components/icons/manufacturers/SchmitzCargobullIcon.vue'
import ReischManufacturerIcon from '@/components/icons/manufacturers/ReischIcon.vue'
import { VehicleCategory } from '@/types/enums'

// MARK: Vehicle Categories

export const assetAttributes = () => {
  return {
    truck: assetTruckAttributes(),
    trailer: assetTrailerAttributes(),
    lkw: assetLkwAttributes(),
  }
}

// MARK: Common Manufacturers

export const commonManufacturers = () => {
  return {
    truck: commonTruckAndLkwManufacturers,
    trailer: commonTrailerManufacturers,
    lkw: commonTruckAndLkwManufacturers,
  }
}

const commonTruckAndLkwManufacturers = [
  {
    slug: 'DAF',
    icon: DafIcon,
  },

  {
    slug: 'MAN',
    icon: ManIcon,
  },
  {
    slug: 'Mercedes-Benz',
    icon: MercedesIcon,
  },

  {
    slug: 'Volvo',
    icon: VolvoIcon,
  },
]

const commonTrailerManufacturers = [
  {
    slug: 'Krone',
    icon: KroneIcon,
  },
  {
    slug: 'Kögel',
    icon: KogelIcon,
  },
  {
    slug: 'Schmitz Cargobull',
    icon: SchmitzCargobullIcon,
  },
  {
    slug: 'Reisch',
    icon: ReischManufacturerIcon,
  },
]

// MARK: Manufacturers

export const manufacturers = () => {
  return {
    truck: truckManufacturers,
    trailer: trailerManufacturers,
    lkw: lkwManufacturers,
  }
}

export const manufacturersByCategory = (category: VehicleCategory) => {
  switch (category) {
    case VehicleCategory.truck:
      return truckManufacturers
    case VehicleCategory.trailer:
      return trailerManufacturers
    case VehicleCategory.lkw:
      return lkwManufacturers
  }
}

const truckManufacturers = [
  'Astra',
  'DAF',
  'Ford',
  'Freightliner',
  'Iveco',
  'Kenworth',
  'Mack',
  'Magirus Deutz',
  'MAN',
  'Mercedes-Benz',
  'Peterbilt',
  'Renault',
  'Scania',
  'Sisu',
  'Sonstige/Other',
  'T-Ax-O',
  'Terberg',
  'Volta',
  'Volvo',
]

const trailerManufacturers = [
  'Aak',
  'Ackermann Fahrzeugbau',
  'Ackermann-Fruehauf',
  'Adamoli',
  'Asnæs',
  'ATM',
  'Benalu',
  'Berdex',
  'Berger',
  'Blumhardt',
  'BNG',
  'Brantner',
  'Broshuis',
  'BSS',
  'Bulthuis',
  'Bunge',
  'Burg',
  'C.C.F.C',
  'Cardi',
  'Carnehl',
  'Chereau',
  'CIMC Silvergreen',
  'CIMC',
  'Contar',
  'DAF',
  'Dapa',
  'Doll',
  'ES-GE',
  'F.C. Meiller',
  'Faymonville',
  'Feldbinder',
  'Ferruz ',
  'FGM',
  'Fliegl',
  'Floor',
  'Fluid',
  'Fruehauf',
  'General Trailer',
  'Gheysen & Verpoort',
  'GM',
  'GMF',
  'Gofa',
  'Goldhofer',
  'Groenewegen',
  'GTI',
  'Hangler',
  'Hendricks',
  'HFR',
  'Hilken',
  'Hobur',
  'Hoffmann',
  'Hönkhaus',
  'HRD',
  'Hüffermann',
  'Humbaur',
  'Isi',
  'Istrail',
  'Itas-Cas',
  'Kaiser',
  'Kässbohrer',
  'Kautec',
  'Kautz Sohn',
  'Kel-Berg',
  'Kempf',
  'Klaeser',
  'Knapen',
  'Kögel',
  'Komasa',
  'Kotschenreuther',
  'Kraemer',
  'Kraker',
  'Kröger',
  'Krone',
  'Lag',
  'Lamberet',
  'Langendorf',
  'Leci',
  'Lecitrailer',
  'Legras',
  'Lider ',
  'Little Dude',
  'Lohr',
  'Lück',
  'Marques',
  'Mega',
  'Meierling',
  'Meiller',
  'Menci',
  'Menci/Santi',
  'Merceron',
  'Meusburger',
  'Molgjer',
  'Montfrauto ',
  'Montracon',
  'Möslein',
  'Müller-Mitteltal',
  'Närko',
  'Netam-Fruehauf',
  'Nooteboom',
  'Nopa',
  'Nova',
  'Orten',
  'Orthaus',
  'Pacton',
  'PAVIC',
  'Pezzaioli',
  'PNO',
  'Rába',
  'Reisch',
  'Renders',
  'Ruthmann',
  'Samro',
  'Sawo',
  'Scheuerle',
  'Schmitz Cargobull',
  'Schwarzmüller',
  'Sdc',
  'Serin ',
  'Setra/Kässbohrer',
  'Snedker',
  'Sommer',
  'Sonstige/Other',
  'Sor',
  'Spitzer',
  'Stas',
  'STU',
  'Talson',
  'TecnoKar',
  'Tirsan',
  'Tisvol',
  'Tranders',
  'Trouillet',
  'Van Hool',
  'Varig',
  'Viberti',
  'Vocol',
  'Wackenhut',
  'Web Trailers',
  'Wecon',
  'Weka',
  'Wielton',
  'Wüllhorst',
  'Zorzi',
]

const lkwManufacturers = [
  'Adamoli',
  'Algema',
  'Alpha',
  'Astra',
  'Atlas',
  'Avia',
  'Daewoo Motor Polska',
  'DAF',
  'Daihatsu',
  'Demag',
  'Demak',
  'DFSK Minitrucks',
  'Epsilon',
  'Fassi',
  'Faun',
  'Fiat',
  'Fitzel',
  'Fleetwood',
  'Ford',
  'Freightliner',
  'Fuchs',
  'Fuso',
  'Ginaf',
  'Gottwald',
  'Grove',
  'Heitling',
  'Hiab',
  'Hmf',
  'Hors',
  'Hyundai',
  'Isuzu',
  'Iveco',
  'Jonsered',
  'Karrena',
  'Kenworth',
  'Kress',
  'Krupp',
  'Liaz',
  'Liebherr',
  'Mack',
  'Magirus Deutz',
  'MAN',
  'Meiller',
  'Mercedes-Benz',
  'Mitsubishi',
  'Multitel',
  'Nissan',
  'Palfinger',
  'Pegaso',
  'Peterbilt',
  'Ppm',
  'Putzmeister',
  'Quantron',
  'Reisch',
  'Renault',
  'Saxas',
  'Scania',
  'Scv',
  'Sisu',
  'Sonstige/Other',
  'Steyr',
  'Tatra',
  'Terberg',
  'Unimog',
  'Ural',
  'Volvo',
  'Volta',
  'VW',
  'Wielton',
]

export const assetTruckAttributes = () => {
  return [
    'Allrad',
    'Klima',
    'Standheizung',
    'Standklimaanlage',
    'Telematik',
    'Zusatzscheinwerfer',
    'Zusatztank',
    'Zwei Betten',
  ].sort()
}

export const assetTrailerAttributes = () => {
  return {
    containerchassis: [],
    wechselbruecken: ['Koffer', 'Plane'],
    planenauflieger: [
      'Coil',
      'Getränkezertifikat',
      'Ladebordwand',
      'Liftachse',
      'Palettenkasten',
      'Rolltor',
      'Telematik',
    ],
    kofferauflieger: [
      'Getränkezertifikat',
      'Ladebordwand',
      'Liftachse',
      'Palettenkasten',
      'Rolltor',
      'Telematik',
    ],
    kuehlauflieger: [
      'Getränkezertifikat',
      'Ladebordwand',
      'Liftachse',
      'Multitemp',
      'Palettenkasten',
      'Rolltor',
      'Telematik',
    ],
    megatrailer: [
      'Coil',
      'Getränkezertifikat',
      'Ladebordwand',
      'Liftachse',
      'Palettenkasten',
      'Rolltor',
      'Telematik',
    ],
    schubbodenauflieger: [
      'Getränkezertifikat',
      'Liftachse',
      'Palettenkasten',
      'Telematik',
    ],
  }
}

export const assetLkwAttributes = () => {
  return [
    'Anhängerkupplung',
    'Getränkezertifikat',
    'Klima',
    'Ladebordwand',
    'Standheizung',
    'Telematik',
    'Zusatzscheinwerfer',
  ].sort()
}
